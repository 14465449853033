var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticStyle:{"top":"20px"},attrs:{"title":"操作","width":900,"visible":_vm.visible,"confirmLoading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id']),expression:"['id']"}]})],1),_c('a-form-item',{attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: '请输入角色名称' }]
          }
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: '请输入角色名称' }]\n          }\n        ]"}],attrs:{"placeholder":"起一个名字"}})],1),_c('a-form-item',{attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"角色说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            rules: [{ required: true, message: '请输入角色说明' }]
          }
        ]),expression:"[\n          'remark',\n          {\n            rules: [{ required: true, message: '请输入角色说明' }]\n          }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-divider'),_c('a-form-item',{attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"拥有权限"}},[_c('div',{staticStyle:{"max-height":"500px","overflow-y":"scroll"}},[_c('a-tree',{attrs:{"checkable":"","treeData":_vm.permissions},on:{"check":_vm.onCheck},model:{value:(_vm.checkedKeys),callback:function ($$v) {_vm.checkedKeys=$$v},expression:"checkedKeys"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }