<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{xs:8}" :wrapper-col="{xs:16}">
        <a-row :gutter="8">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="角色名称">
              <a-input placeholder="请输入" v-model="queryParam.name"/>
            </a-form-item>
          </a-col>
          <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="queryParam.flagEnable" default-value="false">
                <a-select-option :value="''">全部</a-select-option>
                <a-select-option :value="false">正常</a-select-option>
                <a-select-option :value="true">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <!-- <a-button type="primary" icon="plus" @click="$refs.modal.add()">新建</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0"> -->
      <a-button v-if="addEnable" type="primary" icon="plus" @click="$refs.modal.add()">新建</a-button>
      <a-dropdown v-if="removeEnable&&selectedRowKeys.length > 0">
        <!-- <a-menu slot="overlay">
          <a-menu-item key="1"><a-icon type="delete" />删除</a-menu-item>
          <a-menu-item key="2"><a-icon type="lock" />禁用</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">
          批量操作 <a-icon type="down" />
        </a-button> -->
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>
    <s-table
      size="default"
      ref="table"
      rowKey="id"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
      :columns="columns"
      :data="loadData"
    >
      <!-- <div
        slot="expandedRowRender"
        slot-scope="record"
        style="margin: 0">
        <a-row
          :gutter="24"
          :style="{ marginBottom: '12px' }">
          <a-col :span="12" v-for="(role, index) in record.permissions" :key="index" :style="{ marginBottom: '12px' }">
            <a-col :lg="4" :md="24">
              <span>{{ role.permissionName }}：</span>
            </a-col>
            <a-col :lg="20" :md="24" v-if="role.actions.length > 0">
              <a-tag color="cyan" v-for="(action, k) in role.actions" :key="k">{{ action.describe }}</a-tag>
            </a-col>
            <a-col :span="20" v-else>-</a-col>
          </a-col>
        </a-row>
      </div> -->
      <span slot="flagEnable" slot-scope="text,record">
        <a-switch v-model="record.flagEnable" @change="onChangeStatus(record)" />
      </span>
      <span slot="action" slot-scope="text, record">
        <div v-if="!record.flagAllPermission">
          <a v-if="editEnabel" @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a v-if="editEnabel" @click="handleScope(record)">数据权限</a>
          <a-divider type="vertical" />
          <a v-if="removeEnable" @click="delByIds([record.id])">删除</a>
        </div>
      </span>
    </s-table>
    <role-modal ref="modal" @ok="handleOk" />
    <role-scope-modal ref="scopemodal" @ok="handleOk" />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getRoleList, delRole, changRoleStatus } from '@/api/system'
import RoleModal from './modules/RoleModal.vue'
import RoleScopeModal from './modules/RoleScopeModal.vue'
import pick from 'lodash.pick'
import { checkPermission } from '@/utils/permissions'
export default {
  name: 'TableList',
  components: {
    STable,
    RoleModal,
    RoleScopeModal
  },
  data () {
    return {
      // description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name'
        },
        {
          title: '角色说明',
          dataIndex: 'remark'
        },
        {
          title: '是否启用',
          dataIndex: 'flagEnable',
          scopedSlots: { customRender: 'flagEnable' }
        },
        {
          title: '操作',
          width: '200px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return getRoleList(Object.assign(parameter, this.queryParam))
      },
      selectedRowKeys: [],
      selectedRows: [],
      addEnable: checkPermission('system:role:add'),
      editEnabel: checkPermission('system:role:edit'),
      removeEnable: checkPermission('system:role:remove')
    }
  },
  created () {
  },
  methods: {
    onSelectChange (selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    handleAdd (parentId) {
      this.$refs.modal.add(parentId)
    },
    handleEdit (record) {
      this.$refs.modal.edit(record)
    },
    handleScope (record) {
      this.$refs.scopemodal.edit(record)
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk () {
      this.$refs.table.refresh(true)
    },
    delByIds (ids) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除选中角色吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          delRole({ ids: ids.join(',') }).then(res => {
            if (res.code === 200) {
              that.$message.success(res.message)
              that.handleOk()
            } else {
              that.$message.error(res.message)
            }
            // const difference = new Set(this.selectedRowKeys.filter(x => !new Set(ids).has(x)))
            // this.selectedRowKeys = Array.from(difference)
            that.selectedRowKeys = []
          })
        },
        onCancel () {
          // console.log('Cancel')
        }
      })
    },
    onChangeStatus (record) {
      record.flagEnable = !record.flagEnable
      changRoleStatus(pick(record, 'id')).then(res => {
        if(res.code == 200) {
          this.$message.success(res.message)
          this.handleOk()
        }
      })
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
